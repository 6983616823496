@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
.top h2 {
  font-weight: lighter;
  font-size: 50px; }

.top li {
  list-style: none; }

.top a {
  text-decoration: none; }

.top .slider {
  height: auto;
  width: 100%; }
  @media screen and (max-width: 800px) {
    .top .slider {
      height: auto; } }

.top .first-slide {
  height: 100%;
  position: relative;
  background-size: 100%;
  background: url("../../../../../../../resources/c_media/themes/theme_0/images/top/pic_slide_Laboratory_Glassware.png") no-repeat;
  background-position: bottom 80% right 20%;
  background-size: auto;
  background-color: #EFEFEF; }
  @media screen and (max-width: 800px) {
    .top .first-slide {
      background-position: bottom 100% right 70%;
      background-size: auto; } }
  @media screen and (max-width: 600px) {
    .top .first-slide {
      background-position: bottom 100% right 45%;
      height: 100%;
      background-size: auto; } }

.top .slide-text {
  color: #666;
  padding: 70px 0 100px 120px; }
  @media screen and (max-width: 800px) {
    .top .slide-text {
      padding: 90px 10px 80px; } }
  @media screen and (max-width: 600px) {
    .top .slide-text {
      width: 95%;
      margin: 0 auto;
      text-align: center; } }
  .top .slide-text p {
    margin: 0;
    width: 80%; }
    @media screen and (max-width: 600px) {
      .top .slide-text p {
        width: 100%;
        text-align: left;
        font-size: 16px;
        line-height: 2; } }
    @media screen and (max-width: 600px) {
      .top .slide-text p br {
        display: none; } }
  .top .slide-text h2 {
    margin: 20px 0 60px; }
    @media screen and (max-width: 600px) {
      .top .slide-text h2 {
        line-height: 1.1;
        text-align: left;
        margin: 20px 0 30px; } }
    .top .slide-text h2 br {
      display: none; }
      @media screen and (max-width: 600px) {
        .top .slide-text h2 br {
          display: block; } }
  .top .slide-text a {
    width: 240px;
    display: inline-block;
    margin: 30px 0; }
    @media screen and (max-width: 600px) {
      .top .slide-text a {
        margin: 40px auto 0; } }

.top .slick-dots {
  bottom: 40px; }
  @media screen and (max-width: 600px) {
    .top .slick-dots {
      bottom: 30px; } }

.top .contentsArea {
  margin-bottom: 50px; }
  .top .contentsArea h2 {
    color: #666; }
  .top .contentsArea > div {
    border-top: 1px solid #cbcbcb; }
    @media screen and (max-width: 800px) {
      .top .contentsArea > div {
        border: none; } }
  .top .contentsArea .itemTitle {
    padding-top: 100px;
    text-align: center;
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-size: auto; }
    @media screen and (max-width: 800px) {
      .top .contentsArea .itemTitle {
        border-top: 1px solid #cbcbcb;
        padding: 40px 0 0; } }
    .top .contentsArea .itemTitle .sHead {
      color: #666; }
    .top .contentsArea .itemTitle.glassparts {
      background-image: url("../../../../../../../resources/c_media/themes/theme_0/images/top/bg_top_glassparts.png");
      background-position: bottom right -90px;
      border-left: 1px solid #cbcbcb; }
      @media screen and (max-width: 800px) {
        .top .contentsArea .itemTitle.glassparts {
          background-size: contain;
          border-left: none; } }
    .top .contentsArea .itemTitle.apparatus {
      position: relative;
      background: url("../../../../../../../resources/c_media/themes/theme_0/images/top/bg_top_apparatus.png");
      background-repeat: no-repeat;
      background-position: bottom -30px right -100px;
      border-right: 1px solid #cbcbcb; }
      @media screen and (max-width: 800px) {
        .top .contentsArea .itemTitle.apparatus {
          border-right: none;
          background-size: contain; } }
    .top .contentsArea .itemTitle.others {
      text-align: right;
      position: relative;
      background-image: url("../../../../../../../resources/c_media/themes/theme_0/images/top/bg_top_others.png");
      background-position: bottom -140px left -100px;
      padding: 70px 0;
      border-bottom: 1px solid #cbcbcb; }
      @media screen and (max-width: 800px) {
        .top .contentsArea .itemTitle.others {
          text-align: center;
          background-image: url("../../../../../../../resources/c_media/themes/theme_0/images/top/bg_top_others_sp.png");
          background-position: bottom left -80px;
          padding: 40px 0 0;
          background-size: contain; } }
      .top .contentsArea .itemTitle.others h2 {
        position: relative;
        margin: 0 400px 0 0; }
        @media screen and (max-width: 800px) {
          .top .contentsArea .itemTitle.others h2 {
            margin: 0;
            display: block; } }
    .top .contentsArea .itemTitle .btn {
      display: none; }
      @media screen and (max-width: 800px) {
        .top .contentsArea .itemTitle .btn {
          margin: 40px 0 30px;
          display: inline-block;
          width: 240px; } }
